import styled from 'styled-components';
import FONTS from '@constants/fonts';
import Screens from '@constants/screens';
export var DisplaySmall = styled.div.withConfig({
    displayName: "display-smallstyled__DisplaySmall",
    componentId: "sc-170yez4-0"
})(["font:", ";font-size:1.5rem;line-height:2rem;color:", ";", " ", " @media (min-width:", "){font-size:1.5rem;}"], FONTS.foundersGroteskLight, function (_ref) {
    var color = _ref.color;
    return color || 'var(--white)';
}, function (_ref2) {
    var customStyles = _ref2.customStyles;
    return customStyles || '';
}, function (_ref3) {
    var configStyles = _ref3.configStyles;
    return configStyles || '';
}, Screens.sm);
