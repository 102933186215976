import styled from 'styled-components';
import FONTS from '@constants/fonts';
export var StyledH3 = styled.h3.withConfig({
    displayName: "h3styled__StyledH3",
    componentId: "soi99u-0"
})(["font-family:", ";font-size:1.75rem;line-height:1.125rem;color:", ";", " ", ""], FONTS.foundersGroteskBold, function (_ref) {
    var color = _ref.color;
    return color || 'var(--white';
}, function (_ref2) {
    var customStyles = _ref2.customStyles;
    return customStyles || '';
}, function (_ref3) {
    var configStyles = _ref3.configStyles;
    return configStyles || '';
});
