import styled from 'styled-components';
import FONTS from '@constants/fonts';
export var StyledLead = styled.p.withConfig({
    displayName: "leadstyled__StyledLead",
    componentId: "sllkhv-0"
})(["font-family:", ";font-size:1.5rem;line-height:2rem;color:", ";", " ", ""], FONTS.foundersGroteskRegular, function (_ref) {
    var color = _ref.color;
    return color || 'var(--white)';
}, function (_ref2) {
    var customStyles = _ref2.customStyles;
    return customStyles || '';
}, function (_ref3) {
    var configStyles = _ref3.configStyles;
    return configStyles || '';
});
