import styled from 'styled-components';
import FONTS from '@constants/fonts';
export var StyledH4 = styled.h4.withConfig({
    displayName: "h4styled__StyledH4",
    componentId: "sc-1gqr2uz-0"
})(["font-family:", ";font-size:1.12rem;line-height:1.12rem;color:", ";", " ", ""], FONTS.foundersGroteskBold, function (_ref) {
    var color = _ref.color;
    return color || 'var(--white)';
}, function (_ref2) {
    var customStyles = _ref2.customStyles;
    return customStyles || '';
}, function (_ref3) {
    var configStyles = _ref3.configStyles;
    return configStyles || '';
});
