import styled from 'styled-components';
import FONTS from '@constants/fonts';
export var StyledBodyText = styled.div.withConfig({
    displayName: "body-textstyled__StyledBodyText",
    componentId: "r3g6k6-0"
})(["font:", ";line-height:1.25rem;color:", ";", " ", " & >:first-child{margin-top:0;}h4{margin-bottom:1rem;}p + p{margin-top:1rem;}"], FONTS.foundersGroteskRegular, function (_ref) {
    var color = _ref.color;
    return color || 'var(--white)';
}, function (_ref2) {
    var customStyles = _ref2.customStyles;
    return customStyles || '';
}, function (_ref3) {
    var configStyles = _ref3.configStyles;
    return configStyles || '';
});
