import styled from 'styled-components';
import FONTS from '@constants/fonts';
export var StyledTighterLargeText = styled.p.withConfig({
    displayName: "tighter-large-textstyled__StyledTighterLargeText",
    componentId: "sc-12bewvr-0"
})(["font-family:", ";font-size:1.12rem;line-height:1.44rem;color:", ";", " ", ""], FONTS.foundersGroteskRegular, function (_ref) {
    var color = _ref.color;
    return color || 'var(--white)';
}, function (_ref2) {
    var customStyles = _ref2.customStyles;
    return customStyles || '';
}, function (_ref3) {
    var configStyles = _ref3.configStyles;
    return configStyles || '';
});
