import styled from 'styled-components';
import FONTS from '@constants/fonts';
export var StyledH2 = styled.h2.withConfig({
    displayName: "h2styled__StyledH2",
    componentId: "sc-1yzpoco-0"
})(["margin:0;font-family:", ";font-size:1.62rem;line-height:2rem;color:", ";letter-spacing:1px;", " ", ""], FONTS.cindieMonoA, function (_ref) {
    var color = _ref.color;
    return color || 'var(--white)';
}, function (_ref2) {
    var customStyles = _ref2.customStyles;
    return customStyles || '';
}, function (_ref3) {
    var configStyles = _ref3.configStyles;
    return configStyles || '';
});
