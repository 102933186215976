import styled from 'styled-components';
import FONTS from '@constants/fonts';
export var StyledLargeText = styled.div.withConfig({
    displayName: "large-textstyled__StyledLargeText",
    componentId: "gbcz52-0"
})(["font-family:", ";font-size:1.25rem;line-height:1;color:", ";", " ", ""], FONTS.foundersGroteskRegular, function (_ref) {
    var color = _ref.color;
    return color || 'var(--white)';
}, function (_ref2) {
    var customStyles = _ref2.customStyles;
    return customStyles || '';
}, function (_ref3) {
    var configStyles = _ref3.configStyles;
    return configStyles || '';
});
