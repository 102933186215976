import styled from 'styled-components';
import FONTS from '@constants/fonts';
export var StyledSmallText = styled.div.withConfig({
    displayName: "small-textstyled__StyledSmallText",
    componentId: "sc-1h6vuxg-0"
})(["font-family:", ";font-size:0.87rem;line-height:1.19rem;color:", ";", " ", ""], FONTS.foundersGroteskRegular, function (_ref) {
    var color = _ref.color;
    return color || 'var(--white)';
}, function (_ref2) {
    var customStyles = _ref2.customStyles;
    return customStyles || '';
}, function (_ref3) {
    var configStyles = _ref3.configStyles;
    return configStyles || '';
});
