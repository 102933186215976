import styled from 'styled-components';
import FONTS from '@constants/fonts';
var Container = styled.div.withConfig({
    displayName: "textstyled__Container",
    componentId: "sc-1en1045-0"
})(["word-break:break-word;ul{align-self:flex-end;list-style-type:", ";padding:0 0 0 1.87rem;}ul li{margin:0 0 0.56rem 0;padding:0 0 0 1.56rem;}ol{align-self:flex-end;counter-reset:counter;list-style:none;padding:0 0 0 2.06rem;}ol li{&:not(:last-child ){margin:0 0 1rem 0;}padding:0 0 0 1.5rem;counter-increment:counter;position:relative;}ol li::before{content:counter(counter);position:absolute;--size:1rem;font:", ";line-height:1.25;left:calc(-1 * var(--size) - 0.25rem);width:var(--size);height:var(--size);top:0;text-align:end;color:var(--primary);}"], function (_ref) {
    var bulletType = _ref.bulletType;
    switch (bulletType) {
        case 'Trisngle':
            return 'disclosure-closed';
        case 'Circle':
            return 'disc';
        case 'Square':
            return 'square';
        default:
            return 'disclosure-closed';
    }
}, FONTS.foundersGroteskMonoRegular);
export default Container;
