import styled from 'styled-components';
import FONTS from '@constants/fonts';
import Screens from '@constants/screens';
export var DisplayLarge = styled.h3.withConfig({
    displayName: "display-largestyled__DisplayLarge",
    componentId: "sc-1apd1de-0"
})(["font:", ";font-size:1.5rem;line-height:1.125;color:", ";", " ", " span.underline{text-decoration:underline;text-decoration-color:var(--primary);text-decoration-thickness:1px;text-underline-offset:6px;}@media (min-width:", "){font-size:1.75rem;line-height:2;}"], FONTS.foundersGroteskLight, function (_ref) {
    var color = _ref.color;
    return color || 'var(--white)';
}, function (_ref2) {
    var customStyles = _ref2.customStyles;
    return customStyles || '';
}, function (_ref3) {
    var configStyles = _ref3.configStyles;
    return configStyles || '';
}, Screens.sm);
